import React from "react";
import QRCode from "react-qr-code";
import { rootUrl, statusColors } from "../../data/initialValues";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import EditViewFormGenerator from "./EditViewFormGenerator";
import { Link } from "react-router-dom";
/*
const data = 
*/

function EditViewTabMain({ formData, handleChange }) {
  // format Array => fieldname "", label "", options ?.{}
  const fields = [
    ["title", "Event title"],
    ["name", "Event name (URL)"],
    ["description", "Event description"],
    ["date", "Date"],
    ["where", "Location"],
    ["hr"],
    ["host", "Host"],
    ["hostemail", "Email Host"],
    ["hr"],
    ["min", "Min. Income"],
    ["max", "Max. Income"],
    ["hr"],
    ["status", 'Status - type "standalone" to activate standalone'],
  ];
  const props = { fields, formData, handleChange };
  return (
    <Row className="mt-3">
      <Col md={8}>
        <EditViewTabMainForm {...props} />
      </Col>
      <Col md={4}>
        <div className="mb-3 px-3 small">
          <EditViewTabMainInfo {...props} />
        </div>
        <div className="my-3 px-3 ">
          <Button
            variant="outline-danger"
            as={Link}
            to={`/admin/events/${formData.id}/delete`}>
            Delete Event
          </Button>
        </div>
      </Col>
    </Row>
  );
}

function EditViewTabMainForm({ fields, formData, handleChange }) {
  return (
    <div className="form-block">
      {fields.map((field, idx) =>
        field[0] === "hr" ? (
          <hr key={`main${idx}`} />
        ) : (
          <EditViewFormGenerator
            key={`main${idx}`}
            {...{ formData, handleChange, field }}
          />
        )
      )}
    </div>
  );
}

function EditViewTabMainInfo({ formData }) {
  //const event = formData;

  //if (!event) return null;
  const editedOn = formData.edited?.toDate() ?? new Date(0);
  const createdOn = formData.created?.toDate() ?? new Date(0);

  return (
    <>
      <div>
        <QRCode
          value={rootUrl + "e/" + formData.id}
          size={150}
          fgColor={statusColors.dark}
          bgColor="transparent"
        />
      </div>
      <hr />
      <p>
        Created on:{" "}
        {createdOn.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}{" "}
        {createdOn.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        })}
        <br />
        by {formData.createdBy} <br />
        <br />
        Edited on:{" "}
        {editedOn.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}{" "}
        {editedOn.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        })}
        <br />
        by {formData.editedBy}
      </p>

      <p>
        <small className="text-muted">Event ID: {formData.id}</small>
      </p>
      <hr />
    </>
  );
}

export default EditViewTabMain;
