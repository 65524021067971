import React from "react";

import { UpdateButtons, UpdateToggle } from "../utils";

// ICONS
import { FaBeer, FaQrcode, FaFlagCheckered, FaRegFlag } from "react-icons/fa";
import {
  MdMobileFriendly,
  MdOutlinePhonelink,
  MdOutlineSettingsRemote,
} from "react-icons/md";
import { HiOutlinePresentationChartBar } from "react-icons/hi";

import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";

const AdminEventMenu = ({ event, liveData, fullscreen }) => {
  return (
    <div className="fixed-bottom">
      <Dropdown drop="end" autoClose="outside">
        <Dropdown.Toggle
          as={AdminEventRemoteToggle}
          id="dropdown-admin-event-menu">
          <MdOutlineSettingsRemote />
        </Dropdown.Toggle>

        <Dropdown.Menu
          as={AdminEventRemoteMenu}
          className="shadow border-0 bg-dropdown-dark">
          <div className="px-3 d-inline-flex align-items-center gap-2">
            <small className="text-muted">User View </small>
            <UpdateButtons
              values={[
                { label: <FaBeer />, value: "draft" },
                { label: <FaRegFlag />, value: "onboard" },
                { label: <MdMobileFriendly />, value: "active" },
                { label: <FaFlagCheckered />, value: "finished" },
              ]}
              styles={{
                variant: "outline-secondary shadow-none border-0 rounded",
                size: "lg",
                name: "setUserViewState",
                class: "fs-3",
              }}
              path={`events/${event.id}/status`}
              initialValue={liveData.status}
            />
          </div>
          <Dropdown.Divider />

          <div className="px-3 d-inline-flex align-items-center gap-2">
            <small className="text-muted">Presenter </small>
            <UpdateButtons
              values={[
                { label: <FaRegFlag />, value: "start" },
                { label: <HiOutlinePresentationChartBar />, value: "present" },
                { label: <MdOutlinePhonelink />, value: "results" },
                { label: <FaFlagCheckered />, value: "end" },
              ]}
              styles={{
                variant: "outline-secondary shadow-none border-0 rounded",
                size: "lg",
                name: "setPresenterViewState",
                class: "fs-3",
              }}
              path={`events/${event.id}/presenterView`}
              initialValue={liveData.presenterView}
            />
          </div>
          <Dropdown.Divider />
          <div className="px-3 d-inline-flex align-items-center gap-2">
            <UpdateToggle
              label={<FaQrcode />}
              name="toggleQrCode"
              path={`events/${event.id}/showqr`}
              styles={{ class: "shadow-none", size: "lg" }}
              initialValue={liveData.showqr || false}
            />{" "}
            <FullScreenToggle {...{ fullscreen }} />
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const AdminEventRemoteToggle = React.forwardRef(
  ({ children, onClick }, ref) => (
    <button
      ref={ref}
      className="btn btn-link btn-lg p-3 text-reset text-decoration-none shadow-none rounded-0 text-outline-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
    </button>
  )
);

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const AdminEventRemoteMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    // const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}>
        {children}
      </div>
    );
  }
);

export function FullScreenToggle({ fullscreen }) {
  const handleFullScreen = (e) => {
    if (fullscreen.active) {
      fullscreen.exit();
    } else {
      fullscreen.enter();
    }
  };
  return (
    <Button
      variant="outline-secondary shadow-none"
      className={fullscreen.active ? "active" : ""}
      size="lg"
      onClick={handleFullScreen}>
      <i className={fullscreen.active ? "fa fa-times" : "fa fa-arrows-alt"}></i>
    </Button>
  );
}

export default AdminEventMenu;
