/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useFirebaseUpdate } from "../../hooks/FirebaseHooks";

import { UsersNowConnected } from "../utils";
import RangeSlider from "react-bootstrap-range-slider";
import Button from "react-bootstrap/Button";
import { HiPlusCircle, HiMinusCircle } from "react-icons/hi";

function UserViewActive({ event, liveData, userId }) {
  const [income, setIncome] = useState(0);
  const eventId = event.id;

  return (
    <>
      <div className="text-center mt-4">
        <UsersNowConnected {...{ eventId, userId }} />
      </div>
      <div
        className="px-3 mx-auto mt-4 text-center"
        style={{ maxWidth: "450px" }}>
        <SvgCounter {...{ income, event }} />
        <div>
          <div className="mt-5">
            <div>Use slider to adjust your income</div>
          </div>
          <div className="mt-3">
            <IncomeRangeSlider {...{ income, setIncome, event, userId }} />
          </div>
        </div>
      </div>
    </>
  );
}

export function IncomeRangeSlider({
  income,
  setIncome,
  event,
  userId,
  autoSaveOff,
}) {
  const { incrementIncome, useInitialValues } = useFirebaseUpdate();
  const [initialIncome, setInitialIncome] = useState(0);
  const autoSave = autoSaveOff ? false : true;

  const _initialIncome = useInitialValues(`incomes/${event.id}/${userId}`);

  useEffect(() => {
    if (_initialIncome) {
      setInitialIncome(_initialIncome);
      setIncome(_initialIncome);
      console.log("intial values", _initialIncome);
    }
  }, [_initialIncome]);

  useEffect(() => {
    if (!autoSave) return null;
    if (!income || income === initialIncome) return null;
    const timeoutId = setTimeout(() => {
      const _income = income;
      const delta = _income - initialIncome;
      setInitialIncome(income);
      incrementIncome(event.id, userId, _income, delta).then(() => {
        console.log("income set", delta);
      });
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [income]);

  const intervalRef = React.useRef(null);

  useEffect(() => {
    return () => stopCounter();
  }, []);

  const startCounter = (delta) => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setIncome((prevCounter) => prevCounter * 1 + delta);
    }, 100);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const startIncrement = (delta) => {
    setIncome((prevCounter) => prevCounter * 1 + delta);
  };

  return (
    <div className="d-flex align-items-center">
      <div>
        <Button
          className="btn-range-slider shadow-none px-0"
          size="sm"
          variant="link"
          onClick={() => startIncrement(-10)}
          onMouseDown={() => startCounter(-10)}
          onMouseUp={stopCounter}
          onMouseLeave={stopCounter}
          disabled={income > parseInt(event.min) ? false : true}>
          <HiMinusCircle className="fs-1 " />
        </Button>
      </div>
      <div className="flex-grow-1">
        <RangeSlider
          value={income}
          //variant="light"
          onChange={(e) => setIncome(e.target.value)}
          size="lg"
          min={parseInt(event.min) || 1500}
          max={parseInt(event.max) || 13000}
          tooltip="off"
          step={10}
          className="rangeslider"
        />
      </div>
      <div>
        <Button
          className="btn-range-slider shadow-none px-0"
          size="sm"
          variant="link"
          onClick={() => startIncrement(10)}
          onMouseDown={() => startCounter(10)}
          onMouseUp={stopCounter}
          onMouseLeave={stopCounter}
          disabled={income < parseInt(event.max) ? false : true}>
          <HiPlusCircle className="fs-1" />
        </Button>
      </div>
    </div>
  );
}
export function SvgCounter({ income, event }) {
  return (
    <svg viewBox="0 0 282 282" fill="none" preserveAspectRatio="xMinYMin meet">
      <g>
        <rect width="282" height="282" rx="25" fill="#EDF3FF" />
        <text fill="var(--blue)" transform="translate(0,15)">
          <tspan
            x="50%"
            y="14%"
            textAnchor="middle"
            fontWeight="bold"
            fontSize="25px"
            letterSpacing={-1}
            fill="#1F4671">
            My Monthly Income
          </tspan>
          <tspan  
            x="50%" 
            y="113.406" 
            textAnchor="middle"
            fill="#1F4671"
            >
            <tspan 
              fontWeight={800}>
              {event.currency}
            </tspan>
            <tspan
              dx="10"
              fontSize="64px"
              fontWeight="bold"
              letterSpacing={-1}
              fontFamily="Helvetica, Arial"
              fill="#1F4671">
              {income}
            </tspan>
          </tspan>
            <tspan 
              x="50%" 
              y="160"
              fontWeight="bold"
              textAnchor="middle"
              fill="#3980D0">
              My Monthly Contribution
            </tspan>
            <tspan 
              x="50%" 
              y="180"
              fontWeight="bold"
              textAnchor="middle"
              fill="#3980D0">
              to Social Income
            </tspan>
          
          <tspan x="50%" y="238" textAnchor="middle">
            <tspan 
                fontWeight={600}
                fill="#3980D0">
                {event.currency}
            </tspan>
            <tspan
                dx="10"
                fontSize="48px"
                letterSpacing={-1}
                fontWeight={600}
                fontFamily="Helvetica, Arial"
                fill="#3980D0">
                {Math.round(income / 100)}
            </tspan>
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default UserViewActive;
