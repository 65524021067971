import React from "react";

import EditViewFormGenerator from "./EditViewFormGenerator";

function EditViewTabPresenterView({ formData, handleChange }) {
  // format Array => fieldname "", label "", options ?.{}
  const fields = [
    ["h3", "Start Panel"],
    ["presenterViewStart.title", "Title"],
    ["presenterViewStart.desc", "Lead"],
    ["presenterViewStart.content", "Text"],
    ["presenterViewStart.img", "Image / Logo"],
    ["hr"],
    ["h3", "Presentation Panel"],
    ["presenterViewPresent.title", "Title"],
    ["presenterViewPresent.desc", "Lead"],
    ["presenterViewPresent.content", "Text"],
    ["presenterViewPresent.googleSlidesId", "Google Slides ID"],
    ["presenterViewPresent.img", "Image / Logo"],
    ["hr"],
    ["h3", "Results Panel"],
    ["presenterViewResults.title", "Title"],
    ["presenterViewResults.desc", "Lead"],
    ["presenterViewResults.content", "Text"],
    ["presenterViewResults.img", "Image / Logo"],
    ["hr"],
    ["h3", "Finished Panel"],
    ["presenterViewFinished.title", "Title"],
    ["presenterViewFinished.desc", "Lead"],
    ["presenterViewFinished.content", "Text"],
    ["presenterViewFinished.img", "Image / Logo"],
    ["hr"],
  ];

  return (
    <div className="form-block">
      {" "}
      {fields.map((field, idx) =>
        field[0] === "hr" ? (
          <hr key={`user-view-${idx}`} />
        ) : field[0] === "h3" ? (
          <div key={`user-view-${idx}`} className="fw-bold mb-3">
            {" "}
            {field[1]}{" "}
          </div>
        ) : (
          <EditViewFormGenerator
            key={`user-view-${idx}`}
            {...{ formData, handleChange, field }}
          />
        )
      )}{" "}
    </div>
  );
}
export default EditViewTabPresenterView;
