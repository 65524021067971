import firebaseApp from "../services/initialize-firebase";
import {
    getFirestore,
    setDoc,
    updateDoc,
    deleteDoc,
    doc,
    serverTimestamp,
    query,
    collection,
    getDocs,
    where,
    arrayUnion,
} from "firebase/firestore";

const firestoreDB = getFirestore(firebaseApp);

export function useFirestore() {
    const updateEvent = (path, obj, userEmail) => {
        const docRef = doc(firestoreDB, path);
        return updateDoc(docRef, {
            ...obj,
            edited: serverTimestamp(),
            editedBy: userEmail,
        });
    };

    // delete function to be created
    const deleteEvent = (docId, path) => {
        const docRef = doc(firestoreDB, path, docId);
        return deleteDoc(docRef);
    };

    // user update / create
    const updateUser = (docId, payload, path) => {
        const docRef = doc(firestoreDB, path, docId);
        return setDoc(
            docRef, {
                email: payload.email,
                type: payload.type,
                events: arrayUnion(payload.eventId),
            }, { merge: true }
        );
    };

    const checkUser = (email) => {
        const q = query(
            collection(firestoreDB, "events-users"),
            where("email", "==", email)
        );

        return getDocs(q);
    };

    return {
        updateEvent,
        updateUser,
        deleteEvent,
        checkUser,
    };
}

// export default useFirestoreUpdate;