import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
      <div>
        <Link to="/admin/events/list">Manage Events</Link>
      </div>
      <div>
        <Link to="/admin/users">Manage Users</Link>
      </div>
    </div>
  );
}

export default Dashboard;
