import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebaseApp from "../services/initialize-firebase";
import { ref, getDatabase } from "firebase/database";
import { useObject, useList } from "react-firebase-hooks/database";
import { useFirebasePresence, useFirebaseUpdate } from "../hooks/FirebaseHooks";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

// ICONS
import { FaBeer, FaQrcode, FaFlagCheckered, FaRegFlag } from "react-icons/fa";
import {
  MdMobileFriendly,
  MdOutlinePhonelink,
  MdOutlineSettingsRemote,
  MdViewModule,
} from "react-icons/md";
import {
  HiOutlinePresentationChartBar,
  HiViewGrid,
  HiOutlineArrowsExpand,
} from "react-icons/hi";
import { TiEdit, TiCogOutline } from "react-icons/ti";

import Dropdown from "react-bootstrap/Dropdown";

import { useAuth } from "../hooks/useAuth";
const database = getDatabase(firebaseApp);

const DatabaseValue = () => {
  const [snapshot, loading, error] = useObject(
    ref(database, "events/V1LnaLArY0snQbr0ZDBh")
  );
  const [adminsnapshot, adminloading, adminerror] = useObject(
    ref(database, "presenter/V1LnaLArY0snQbr0ZDBh")
  );

  return (
    <div>
      <p>
        {" "}
        {error && <strong> Error: {error} </strong>}{" "}
        {loading && <span> Value: Loading... </span>}{" "}
        {snapshot && (
          <>
            <span>
              Income: {snapshot.val().income}
              <br />
              Status: {snapshot.val().status}
            </span>
          </>
        )}{" "}
        {adminsnapshot && (
          <span>
            QR Code: {adminsnapshot.val().showqr ? "Showed" : "Hidden"}
          </span>
        )}
      </p>{" "}
    </div>
  );
};
const UsersNowConnected = ({ eventId, userId }) => {
  const [state, setState] = useState(0);
  const [list, loading, error] = useList(ref(database, `isOnline/${eventId}`));
  // use Firbase Presence
  const connected = useFirebasePresence(
    database,
    `isOnline/${eventId}/${userId}`
  );
  // action when connected (ev. to delete ?)
  useEffect(() => {
    if (!connected) return null;
    console.log("user online");
  }, [connected]);

  useEffect(() => {
    if (!list) return null;
    const onlineUsers = list.reduce((prev, curr) => {
      return { ...prev, [curr.key]: true };
    }, {});
    console.log(Object.keys(onlineUsers).length);
    setState(Object.keys(onlineUsers).length);
  }, [list]);

  return (
    <>
      {loading && <span> Loading... </span>}{" "}
      {state > 1
        ? state + " users now connected "
        : state === 1
        ? "You are the only one here"
        : "No one around"}
    </>
  );
};

const UpdateButtons = ({ values, path, styles = {}, initialValue = "" }) => {
  const { updateOne } = useFirebaseUpdate(database);
  const [selected, setSelected] = useState(initialValue);
  // buttons action move to component

  const handleChange = (newValue) => {
    setSelected(newValue);
    updateOne(newValue, path);
    console.log("user update", path, selected);
  };

  return (
    <ToggleButtonGroup
      type="radio"
      name={styles.name}
      value={selected}
      onChange={handleChange}
      className={styles.class}
      vertical={styles.vertical ? true : false}>
      {values.map((bt, idx) => {
        return (
          <ToggleButton
            key={`btn-${bt.value}-${idx}`}
            id={`btn-${bt.value}-${idx}`}
            value={bt.value}
            variant={styles.variant}
            size={styles.size}>
            {bt.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

const UpdateToggle = ({
  label,
  name,
  path,
  styles = {},
  initialValue = false,
}) => {
  //const eventId = event.id;
  const [checked, setChecked] = useState(initialValue);
  const { updateOne } = useFirebaseUpdate(database);
  // buttons action move to component

  const handleToggle = (e) => {
    setChecked(e.currentTarget.checked);
    updateOne(!checked, path);
  };

  return (
    <ToggleButton
      id={`btn-${name}`}
      className={styles.class}
      variant={styles.variant ?? "outline-secondary"}
      size={styles.size}
      type="checkbox"
      checked={checked}
      value="1"
      onChange={handleToggle}>
      {label}
    </ToggleButton>
  );
};

function Test({ setPageTitle }) {
  // data needed for hook to work
  const auth = useAuth();
  const userId = auth.user.uid;
  const event = { id: "V1LnaLArY0snQbr0ZDBh", status: "onboard" };
  const eventId = event.id;

  useEffect(() => {
    setPageTitle(<MenuDropDown eventId={eventId} />);
  }, []);

  return (
    <>
      <div>
        <DatabaseValue />
        <br />
        <span>
          <UsersNowConnected {...{ eventId, userId }} />
        </span>
        <UpdateButtons
          values={[
            { label: "Draft", value: "draft" },
            { label: "Onboard", value: "onboard" },
            { label: "Active", value: "active" },
            { label: "Finished", value: "finished" },
          ]}
          styles={{
            variant: "outline-secondary shadow-none",
            vertical: true,
            size: "lg",
            name: "setUserViewStateBig",
            class: "d-flex",
          }}
          path={`events/${eventId}/status`}
        />

        <UpdateToggle
          label={<FaQrcode />}
          name="toggleQrCode"
          path={`presenter/${eventId}/showqr`}
          styles={{ class: "fs-3 shadow-none" }}
        />
        <div>
          <UpdateButtons
            values={[
              { label: <FaBeer />, value: "draft" },
              { label: <FaRegFlag />, value: "onboard" },
              { label: <MdMobileFriendly />, value: "active" },
              { label: <FaFlagCheckered />, value: "finished" },
            ]}
            styles={{
              variant: "outline-secondary shadow-none",
              size: "lg",
              name: "setUserViewState",
              class: "fs-3",
            }}
            path={`events/${eventId}/status`}
          />
        </div>
        <div>
          <UpdateButtons
            values={[
              { label: <FaBeer />, value: "draft" },
              { label: <FaRegFlag />, value: "onboard" },
              { label: <HiOutlinePresentationChartBar />, value: "presenter" },
              { label: <MdOutlinePhonelink />, value: "results" },
              { label: <FaFlagCheckered />, value: "finished" },
            ]}
            styles={{
              variant: "outline-secondary shadow-none",
              size: "lg",
              name: "setPresenterViewState",
              class: "fs-3",
            }}
            path={`presenter/${eventId}/view`}
          />
        </div>
      </div>{" "}
      <div className="fixed-bottom">
        <AdminEventRemote event={event} />
      </div>
    </>
  );
}

const MenuDropDown = ({ eventId }) => {
  return (
    <Dropdown drop="end">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        ECAL Meet up 2022
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} className="shadow border-0">
        <Dropdown.Header>Menu title</Dropdown.Header>
        <Dropdown.Item
          eventKey="1"
          as={Link}
          to={`/admin/events/${eventId}/edit`}>
          <TiEdit /> Edit{" "}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          as={Link}
          to={`/admin/events/${eventId}/presenter`}>
          <HiOutlinePresentationChartBar /> Present
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="3"
          as={Link}
          to={`/admin/events/${eventId}/remote`}>
          <MdOutlineSettingsRemote /> Control
        </Dropdown.Item>
        <Dropdown.Item eventKey="1" as={Link} to={`/e/${eventId}`}>
          <MdMobileFriendly /> View
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="1" as={Link} to={`/admin/events/list`}>
          <MdViewModule /> All Events
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    className="text-reset text-decoration-none fw-bold"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    {children}
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}>
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const AdminEventRemote = ({ event }) => {
  return (
    <Dropdown drop="end" autoClose={false}>
      <Dropdown.Toggle
        as={AdminEventRemoteToggle}
        id="dropdown-custom-components">
        <MdOutlineSettingsRemote />
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={AdminEventRemoteMenu}
        className="shadow border-0 bg-dropdown-dark">
        <div className="px-3 d-inline-flex align-items-center gap-2">
          <small className="text-muted">User View </small>
          <UpdateButtons
            values={[
              { label: <FaBeer />, value: "draft" },
              { label: <FaRegFlag />, value: "onboard" },
              { label: <MdMobileFriendly />, value: "active" },
              { label: <FaFlagCheckered />, value: "finished" },
            ]}
            styles={{
              variant: "outline-secondary shadow-none border-0 rounded",
              size: "lg",
              name: "setUserViewState",
              class: "fs-3",
            }}
            path={`events/${event.id}/status`}
          />
        </div>
        <Dropdown.Divider />

        <div className="px-3 d-inline-flex align-items-center gap-2">
          <small className="text-muted">Presenter </small>
          <UpdateButtons
            values={[
              { label: <FaRegFlag />, value: "onboard" },
              { label: <HiOutlinePresentationChartBar />, value: "presenter" },
              { label: <MdOutlinePhonelink />, value: "results" },
              { label: <FaFlagCheckered />, value: "finished" },
            ]}
            styles={{
              variant: "outline-secondary shadow-none border-0 rounded",
              size: "lg",
              name: "setPresenterViewState",
              class: "fs-3",
            }}
            path={`presenter/${event.id}/view`}
          />
        </div>
        <Dropdown.Divider />
        <div className="px-3 d-inline-flex align-items-center gap-2">
          <UpdateToggle
            label={<FaQrcode />}
            name="toggleQrCode"
            path={`presenter/${event.id}/showqr`}
            styles={{ class: "fs-3 shadow-none" }}
          />{" "}
          <UpdateToggle
            label={<HiOutlineArrowsExpand />}
            name="toggleFullScreen"
            path={`presenter/${event.id}/showqr`}
            styles={{ class: "fs-3 shadow-none" }}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const AdminEventRemoteToggle = React.forwardRef(
  ({ children, onClick }, ref) => (
    <button
      ref={ref}
      className="btn btn-link btn-lg p-3 text-reset text-decoration-none shadow-none rounded-0 text-outline-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
    </button>
  )
);

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const AdminEventRemoteMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    // const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}>
        {children}
      </div>
    );
  }
);

export default Test;
