import React from "react";
import firebaseApp from "../services/initialize-firebase";
import {
    update,
    ref,
    increment,
    onValue,
    onDisconnect,
    set,
    getDatabase,
} from "firebase/database";
import { useObject, useList } from "react-firebase-hooks/database";

// const firestoreDB = getFirestore(firebaseApp);
const realtimeDB = getDatabase(firebaseApp);

export function useFirebaseUpdate() {
    // special increment income
    const incrementIncome = (eventId, userId, income, delta) => {
        return update(ref(realtimeDB), {
            [`incomes/${eventId}/${userId}`]: income,
            [`events/${eventId}/total`]: increment(delta),
        });
    };

    const updateOne = (value, path) => {
        return update(ref(realtimeDB), {
            [path]: value,
        });
    };

    const updateMany = (obj) => {
        return typeof obj === "object" ? update(ref(realtimeDB), obj) : null;
    };

    const useInitialValues = (path) => {
        const [initialValue, loadingInitialValue] = useObject(
            ref(realtimeDB, path)
        );
        return initialValue ? initialValue.val() : false;
    };

    return {
        incrementIncome,
        updateOne,
        updateMany,
        useInitialValues,
    };
}

export function useFirebasePresence(database, path) {
    const userRef = ref(database, path);

    React.useEffect(() => {
        onValue(ref(database, ".info/connected"), (snapshot) => {
            if (snapshot.val() === false) {
                set(userRef, null);
                return;
            }
            onDisconnect(userRef)
                .set(null)
                .then(function() {
                    set(userRef, true);
                });
        });
        return () => {
            console.log("disconnected from event");
            set(userRef, null);
        };
    }, []);
    // ref(db, ".info/connected")
    return;
}

export default useFirebasePresence;