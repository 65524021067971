import React from "react";
import { useFirebaseUpdate } from "../../hooks/FirebaseHooks";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { impactPhrases } from "../../data/initialValues";
import { useFirestore } from "../../hooks/FirestoreHooks";

const redirectBaseUrl = "https://socialincome.org/get-involved-individual";

const content = {
  yourimpact: "Your impact",
  yourcontribution: "Your monthly contribution",
  yourcontributiondesc:
    "The people in need receive your contribution directly on their mobile phones.",
  keepintouch: "Let's keep in touch",
  keepintouchplaceholder: "Enter your email",
  keepintouchdesc:
    "In order to access this page in the future, to be informed of Social Income activities or simply to keep in touch, please enter your email",
  save: "Register",
  permonth: "/ Month",
  makedifference: "Make a difference with",
};

function UserViewFinished({ event, income, userId }) {
  const { useInitialValues } = useFirebaseUpdate();
  //const [initialIncome, setInitialIncome] = useState(0);

  const _initialIncome = useInitialValues(`incomes/${event.id}/${userId}`) || 0;

  const panelData = event.userViewFinished || {};
  const _income = Math.round(_initialIncome / 100);
  const letsKeepInTouchProps = { event, userId };

  return (
    <Row>
      <Col xs={12}>
        <TopPanel {...panelData} />
      </Col>
      <Col md={6}>
        <YourImpactCard income={_income} currency={event.currency} />
      </Col>
      <Col md={6}>
        <LetsKeepInTouchForm {...letsKeepInTouchProps} />
      </Col>
    </Row>
  );
}

function TopPanel({ title, desc, content }) {
  // retrive Panel data from event
  // use previous standart panel view
  return (
    <div className="p-5 my-5 text-center">
      <h3 className="display-1 mt-5">{title}</h3>
      <p className="lead">{desc}</p>
      <p>{content}</p>
    </div>
  );
}

function YourImpactCard({ income, currency }) {
  // impact phrase
  const counts = Object.keys(impactPhrases);
  const output = counts.reduce(
    (prev, curr) => (curr < income ? curr : prev)
    //Math.abs(curr - income) < Math.abs(prev - income) ? curr : prev
  );
  const outputPhrase = impactPhrases[output].replace(
    "%%amount%%",
    currency + " " + income
  );
  // make a difference button
  const url = `${redirectBaseUrl}?currency=${currency}&salary=${income}&showinterval=1`;

  // console.log(`${output} ${currency}`);
  return (
    <>
      <Card className="text-dark p-3">
        <Card.Body>
          <Card.Title className="fw-bold text-uppercase text-warning">
            {content.yourimpact}
          </Card.Title>
          <Card.Text className="fw-bold">{outputPhrase}</Card.Text>
          <Card.Title className="fw-bold text-uppercase mt-5 text-warning">
            {content.yourcontribution}
          </Card.Title>
          <Card.Text>
            <span className="fs-3 fw-bold text-uppercase">
              {income} {currency}
            </span>
            <br />
            <br />

            <span>{content.yourcontributiondesc}</span>
          </Card.Text>
          <LetsMakeItButton {...{ url, income, currency }} />
        </Card.Body>
      </Card>
      <br />{" "}
    </>
  );
}

function LetsMakeItButton({ url, income, currency }) {
  return (
    <>
      <div className="text-center">
        <a
          href={url}
          className="btn btn-lg btn-primary w-100"
          target="_blank"
          rel="noreferrer">
          {content.makedifference} {income} {currency}
        </a>
      </div>
    </>
  );
}
// get the form in separate file ?

function LetsKeepInTouchForm({ event, userId }) {
  const { checkUser, updateUser } = useFirestore();

  const handleSubmit = (e) => {
    e.preventDefault();
    const userEmail = e.target.elements.email.value;
    // validation:
    // user already exists ?
    // REDO --> prepare payload and update anyway
    checkUser(userEmail)
      .then((data) => {
        console.log("okay made it");
        if (!data.empty) {
          const snapshot = data.docs[0].data();
          // return snapshot.data();
          console.log("User already registered", snapshot);
        } else {
          console.log("user doesnt exist");
        }
        updateUser(
          userId,
          { email: userEmail, type: "liveappuser", eventId: event.id },
          "events-users"
        );
      })
      .catch(() => console.log("error checking")); // Y --> check user type
    // N --> create new user
    // ok data valid, save it
    // user object = {id,displayName,email,type="liveappUser"}
    console.log("and here we have the data", e.target.elements.email.value);

    /*
      .then(() => console.log("yay"))
      .catch(() => console.log("meeee there was an error"));
      */

    // change input view (disabled)
    e.target.elements.email.disabled = true;
  };

  return (
    <Card className="text-dark p-3">
      <Card.Body>
        <Card.Title className="fw-bold text-uppercase text-warning">
          {content.keepintouch}
        </Card.Title>
        <Card.Text>{content.keepintouchdesc}</Card.Text>
        <Form className="mt-4" onSubmit={handleSubmit}>
          <Form.Control
            type="email"
            name="email"
            placeholder={content.keepintouchplaceholder}
            size="lg"
            // placeholder={name}
          />
          <Button className="w-100 mt-3" size="lg" type="submit">
            {content.save}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default UserViewFinished;
