/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useCountUp } from "react-countup";
import { CountOnlineUsers } from "../utils";

// import QRCode from "react-qr-code";
// import { rootUrl } from "../../data/initialValues";

function PresenterViewResults() {
  const [event, liveData] = useOutletContext();
  const [stats, setStats] = useState({});
  const nowOnline = <CountOnlineUsers eventId={event.id} />;
  // STREAM INCOME
  useEffect(() => {
    if (liveData.total) {
      const _stats = {
        sum: liveData.total,
        support: Math.round(liveData?.total / 100),
        supported: Math.ceil(liveData?.total / (30 * 100)),
        // créer nouvelle fonction pour compter les participants
        participants: liveData.participants || 0,
      };
      setStats({ ..._stats });
    }
  }, [liveData]);

  // automatise with load ?
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <PresenterActiveSVG {...{ stats, nowOnline }} />;
}

function PresenterActiveSVG({ stats, nowOnline }) {
  return (
    <>
      <Row>
        <Col xs={6} lg={4} className="mb-4">
          <SvgCard
            {...{
              lineSm: "Now Online",
              lineLg: "People Participated",
              counterSm: nowOnline,
              counterLg: (
                <SvgCounter id="cntparticipants" value={stats.participants} />
              ),
            }}
          />
        </Col>
        <Col xs={6} lg={4} className="mb-4">
          <SvgCard
            {...{
              lineSm: "Total Income (CHF/month)",
              //+event.currency
              lineLg: "Collective Contribution (1%)",
              counterSm: <SvgCounter id="cntsum" value={stats.sum} />,
              counterLg: <SvgCounter id="cntsupport" value={stats.support} />,
            }}
          />
        </Col>
        <Col xs={6} lg={4} className="mb-4">
          <SvgCard
            {...{
              lineSm: "Together You Empower",
              lineLg: "People in Poverty",
              counterSm: "♡",
              counterLg: (
                <SvgCounter id="cntsupported" value={stats.supported} />
              ),
            }}
          />
        </Col>
      </Row>
    </>
  );
}

function SvgCard({ lineSm, counterSm, lineLg, counterLg }) {
  return (
    <svg className="results" viewBox="0 0 422 422" fill="none">
      <g>
        <rect width="422" height="422" rx="25" fill="#EDF3FF"></rect>
        <text y="0" textAnchor="end" width="422" x="400">
          <tspan fontSize="48" x="90%" y="20%">
            {counterSm}
          </tspan>
          <tspan fontSize="25" letterSpacing="-1.17" x="90%" y="30%">
            {lineSm}
          </tspan>
          <tspan
            x="90%"
            fontSize="170"
            fontWeight="bold"
            letterSpacing="-8px"
            fontFamily="Helvetica, Arial"
            y="79%">
            {counterLg}
          </tspan>
          <tspan fontSize="25" letterSpacing="-1.17" x="90%" y="90%">
            {lineLg}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

function SvgCounter({ id, value }) {
  const { update } = useCountUp({
    ref: id,
    end: value || 0,
    preserveValue: true,
    useEasing: true,
  });
  useEffect(() => {
    if (value) update(value);
  }, [value]);
  return <tspan id={id}></tspan>;
}

export default PresenterViewResults;
