import { Link } from "react-router-dom";

function UserViewOnboard({ event }) {
  return (
    <div className="p-5 mt-5 text-center">
      <h2>{event.title}</h2>
      <div className="d-flex align-items-center justify-content-center gap-2 small">
        <span>Event ID:</span>
        <Link as="a" className="text-reset" to={"/e/" + event.id}>
          {event.name}
        </Link>
      </div>
      <h3 className="display-1 mt-5">Welcome on Board</h3>
      <span>Ready to help out ?</span>
    </div>
  );
}

export default UserViewOnboard;
