import React from "react";
import { Link } from "react-router-dom";

// ICONS
import {
  MdMobileFriendly,
  MdOutlineSettingsRemote,
  MdViewModule,
} from "react-icons/md";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { TiEdit } from "react-icons/ti";

import Dropdown from "react-bootstrap/Dropdown";

const AdminEventTopMenu = ({ event }) => {
  const eventId = event.id;
  return (
    <Dropdown drop="end">
      <Dropdown.Toggle as={MenuDropDownToggle} id="dropdown-custom-components">
        {event.title}
      </Dropdown.Toggle>

      <Dropdown.Menu as={MenuDropDownMenu} className="shadow border-0">
        <Dropdown.Header>Actions</Dropdown.Header>
        <Dropdown.Item
          eventKey="1"
          as={Link}
          to={`/admin/events/${eventId}/edit`}>
          <TiEdit /> Edit{" "}
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          as={Link}
          to={`/admin/events/${eventId}/presenter`}>
          <HiOutlinePresentationChartBar /> Present
        </Dropdown.Item>
        {/*} <Dropdown.Item
          eventKey="3"
          as={Link}
          to={`/admin/events/${eventId}/remote`}>
          <MdOutlineSettingsRemote /> Control
  </Dropdown.Item>{*/}
        <Dropdown.Item eventKey="1" as={Link} to={`/e/${eventId}`}>
          <MdMobileFriendly /> View
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="1" as={Link} to={`/admin/events/list`}>
          <MdViewModule /> All Events
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const MenuDropDownToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    className="text-reset text-decoration-none fw-bold"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    {children}
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const MenuDropDownMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    //const [value, setValue] = useState("");
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}>
        <ul className="list-unstyled">{children}</ul>
      </div>
    );
  }
);

export default AdminEventTopMenu;
