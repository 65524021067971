/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import { statusColors } from "../../data/initialValues";

import { AdminHeader } from "../../App";
import PresenterViewResults from "./PresenterViewResults";
import PresenterViewStart from "./PresenterViewStart";
import PresenterViewDraft from "./PresenterViewDraft";
import PresenterViewFinished from "./PresenterViewFinished";
import PresenterViewPresent from "./PresenterViewPresent";
import PresenterViewQrCode from "./PresenterViewQrCode";

// ****
//PRESENTER
function PresenterView({ setBgColor, fullscreen, setPageTitle }) {
  const [event, liveData] = useOutletContext();

  // livedata presenter views: draft, start, present, results, finished
  const _status = liveData.presenterView || "results";

  //  BACKGROUND
  useEffect(() => {
    setBgColor({
      // color: statusColors[liveData.status] ?? statusColors.active,
      color: statusColors.active,
      text: statusColors.light,
      // liveData.status === "active" ? statusColors.light : statusColors.dark,
      time: ".75s",
    });
    return () => {
      setBgColor({});
    };
  }, [event, setBgColor, setPageTitle]);

  return (
    <>
      <AdminHeader {...{ fullscreen, event }} />
      <div className={liveData.showqr ? "d-block" : "d-none"}>
        <PresenterViewQrCode />
      </div>
      <div className={!liveData.showqr ? "d-block" : "d-none"}>
        {_status === "start" ? (
          <PresenterViewStart />
        ) : _status === "present" ? (
          <PresenterViewPresent />
        ) : _status === "results" ? (
          <PresenterViewResults />
        ) : _status === "end" ? (
          <PresenterViewFinished />
        ) : (
          <PresenterViewDraft />
        )}
      </div>
    </>
  );
}

export default PresenterView;
