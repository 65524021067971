import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import Button from "react-bootstrap/Button";
// import QRCode from "react-qr-code";

// import { statusColors, rootUrl } from "../../data/initialValues";
// import { CountOnlineUsers } from "../utils";

function PresenterViewPresent() {
  const [bt, setBt] = useState(1);
  const [event] = useOutletContext();
  const panel = event.presenterViewPresent || {};
  // console.log("Event trop panel onboard", panel);

  const ref = React.useRef();
  useEffect(() => {
    ref.current.focus();
  }, [bt]);

  const slidesId = panel.googleSlidesId ? panel.googleSlidesId : event.slidesid;

  return (
    <div>
      <div className="responsive169">
        <iframe
          ref={ref}
          id="iframeslide"
          title="slidesframe"
          src={
            event.slidesid &&
            `https://docs.google.com/presentation/d/${slidesId}/embed?start=false&loop=true&rm=minimal`
          }
          frameBorder="0"
          onLoad={(e) => console.log("laoded")}
          allowFullScreen={true}
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          onFocus={(e) => console.log("focused")}
        />
      </div>
      {/*}<Button onClick={(e) => setBt(bt + 1)}>Click me</Button>{*/}
    </div>
  );
}

export default PresenterViewPresent;
