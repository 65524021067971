import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useAuth } from "../../hooks/useAuth";

//import { statusColors } from "../../App";

//import "./Login.css";

function Login({ bgColor, setBgColor }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const afterSignIn = location.state?.from.pathname ?? "/admin";
  const auth = useAuth();
  //const userId = auth.user.uid;

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    auth
      .signin(email, password)
      .then((r) => navigate(afterSignIn))
      .catch((e) => {
        console.log("errors", e.code);
        setError(e.code);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 4, offset: 4 }} className="mt-5">
            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={handleSubmit} className="mt-5">
              <FloatingLabel
                label="email"
                className="mb-3"
                controlId={"inputEmail"}>
                <Form.Control
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FloatingLabel>
              <FloatingLabel
                label="password"
                className="mb-3"
                controlId={"inputPassword"}>
                <Form.Control
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FloatingLabel>
              <Button variant="primary" type="Submit">
                Login
              </Button>{" "}
              <Button
                variant="link"
                onClick={() => {
                  setError("");
                  auth
                    .signout()
                    .then((r) => console.log("signout OK"))
                    .catch((e) => {
                      console.log("errors", e.code);
                      setError(e.code);
                    });
                }}>
                Signout{" "}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Login;
