import { useOutletContext } from "react-router-dom";
import { rootUrl } from "../../data/initialValues";

import QRCode from "react-qr-code";

function PresenterViewDraft() {
  const [event] = useOutletContext();
  return (
    <div className="text-center">
      <div className="mt-5 p-3">
        <QRCode
          value={rootUrl + event.name}
          size={150}
          bgColor="transparent"
          fgColor={event.status === "active" ? "white" : "var(--dark)"}
          renderas="svg"
        />
        <br />
        <span>scan to participate</span>
      </div>
    </div>
  );
}

export default PresenterViewDraft;
