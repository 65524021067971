/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { statusColors } from "../../data/initialValues";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import EditViewTabMain from "./EditViewTabMain";
import { useFirestore } from "../../hooks/FirestoreHooks";
import EditViewTabUserView from "./EditViewTabUserView";
import EditViewTabPresenterView from "./EditViewTabPresenterView";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function EditView({ bgColor, setBgColor }) {
  // eslint-disable-next-line no-unused-vars
  const [event, liveData, userId, userEmail] = useOutletContext();

  const [isDirty, setIsDirty] = useState(false);
  const [formData, setFormData] = useState({ ...event });
  const [formDataToUpdate, setFormDataToUpdate] = useState({});
  const [autoSaveStatus, setautoSaveStatus] = useState("Autosave On");

  const { updateEvent } = useFirestore();

  useEffect(() => {
    setBgColor({
      color: statusColors.admin,
    });
  }, [setBgColor]);

  useEffect(() => {
    setFormData({ ...event });
  }, [event]);

  // Saving form function
  const saveForm = (data) => {
    setautoSaveStatus("Saving... ");
    updateEvent(`events/${event.id}`, data, userEmail).then(() => {
      setIsDirty(false);
      let now = new Date();

      setFormDataToUpdate({});
      setautoSaveStatus(
        "Saved! " +
          now.toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })
      );
    });
  };

  // AUTOSAVE THINGS
  useEffect(() => {
    if (!isDirty) return null;
    const timeoutId = setTimeout(() => {
      saveForm(formDataToUpdate);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [formDataToUpdate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveForm(formDataToUpdate);
    return null;
  };

  const handleChange = (e) => {
    // console.log("handle change called");
    if (!isDirty) {
      setIsDirty(true);
    }
    setautoSaveStatus("Unsaved");
    setFormDataToUpdate({
      ...formDataToUpdate,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const props = {
    formData,
    handleChange,
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ height: "25px" }}>
          {isDirty ? (
            <Button variant="link" type="submit">
              Save
            </Button>
          ) : (
            ""
          )}
          {autoSaveStatus}
        </div>

        <Tabs defaultActiveKey="main" id="edit-form-tabs">
          <Tab eventKey="main" title="Main" className="bg-light p-3">
            <EditViewTabMain {...props} />
          </Tab>
          <Tab
            eventKey="userview"
            title="User View Panels"
            className="bg-light p-3">
            <EditViewTabUserView {...props} />
          </Tab>
          <Tab
            eventKey="presenterview"
            title="Presenter View Panels"
            className="bg-light p-3">
            <EditViewTabPresenterView {...props} />
          </Tab>
        </Tabs>
      </Form>
    </>
  );
}

export default EditView;
