/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";

import { statusColors } from "../../data/initialValues";

import { StreamEvent, StreamLiveData } from "../_utils/Streamers";
import UserViewActive from "./UserViewActive";
import UserViewOnboard from "./UserViewOnboard";
import UserViewDraft from "./UserViewDraft";
import UserViewFinished from "./UserViewFinished";
import AdminEventTopMenu from "../_utils/AdminEventTopMenu";
import StandaloneUserView from "../Standalone/StandaloneUserView";

function UserView({ setBgColor, setPageTitle }) {
  let auth = useAuth();
  const userId = auth.user.uid;
  const userEmail = auth.user.email;

  return (
    <StreamEvent>
      <StreamLiveData pathType="userview">
        <Panels {...{ userId, userEmail, setBgColor, setPageTitle }} />
      </StreamLiveData>
    </StreamEvent>
  );
}

function Panels({
  event,
  liveData,
  userId,
  userEmail,
  setBgColor,
  setPageTitle,
}) {
  const props = { event, liveData, userId };
  useEffect(() => {
    setBgColor({
      // color: statusColors[liveData.status] ?? statusColors.active,
      color: statusColors.active,
      text: statusColors.light,
      // liveData.status === "active" ? statusColors.light : statusColors.dark,
      time: ".75s",
    });
    const pageTitle = userEmail ? (
      <AdminEventTopMenu {...props} />
    ) : (
      <b>{event.title}</b>
    );
    setPageTitle(pageTitle);
    return () => setPageTitle("");
  }, [setBgColor, setPageTitle]);

  if (event.status === "standalone") {
    return <StandaloneUserView {...props} />;
  }

  return (
    <>
      {liveData.status === "active" ? (
        <UserViewActive {...props} />
      ) : liveData.status === "onboard" ? (
        <UserViewOnboard {...props} />
      ) : liveData.status === "draft" ? (
        <UserViewDraft {...props} />
      ) : liveData.status === "finished" ? (
        <UserViewFinished {...props} />
      ) : (
        <UserViewDraft {...props} />
      )}
    </>
  );
}

export default UserView;
