import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";

function EditViewFormGenerator({ formData, handleChange, field }) {
  const textAreaFieldNames = [
    "userViewOnboard.content",
    "userViewFinished.content",
    "description",
  ];
  const [name, label, options] = field;
  // get default Value
  // reducer 2021 conditional chaining operator
  const defaultValue = name.split(".").reduce((r, k) => r?.[k], formData) || "";

  const props = { name, defaultValue, handleChange };
  if (name === "hr") return <hr key={name} />;
  return (
    <FloatingLabel
      label={label}
      className="mb-3"
      controlId={"inputField" + name}>
      {textAreaFieldNames.includes(name) ? (
        <InputTextArea {...props} />
      ) : options ? (
        <InputSelect {...props} />
      ) : (
        <InputText {...props} />
      )}
    </FloatingLabel>
  );
}

function InputText({ name, defaultValue, handleChange }) {
  return (
    <Form.Control
      type="text"
      name={name}
      // placeholder={name}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  );
}

function InputTextArea({ name, defaultValue, handleChange }) {
  console.log("textarea here!");
  return (
    <Form.Control
      as="textarea"
      name={name}
      // placeholder={ph}
      defaultValue={defaultValue}
      onChange={handleChange}
      style={{ minHeight: "120px" }}
    />
  );
}

function InputSelect({ name, defaultValue, handleChange }) {
  return (
    <Form.Select
      name={name}
      // placeholder={field}
      defaultValue={defaultValue}
      onChange={handleChange}>
      <option> Default select </option>{" "}
    </Form.Select>
  );
}

export default EditViewFormGenerator;
