import React, { useState, useEffect } from "react";
import firebaseApp from "../services/initialize-firebase";
import { ref, getDatabase } from "firebase/database";
import { useObject, useList } from "react-firebase-hooks/database";
import { useFirebasePresence, useFirebaseUpdate } from "../hooks/FirebaseHooks";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

const database = getDatabase(firebaseApp);

export const UsersNowConnected = ({ eventId, userId }) => {
  const [state, setState] = useState(0);
  const [list, loading, error] = useList(ref(database, `isOnline/${eventId}`));
  // use Firbase Presence
  useFirebasePresence(database, `isOnline/${eventId}/${userId}`);

  useEffect(() => {
    if (!list) return null;
    const onlineUsers = list.reduce((prev, curr) => {
      return { ...prev, [curr.key]: true };
    }, {});
    console.log(Object.keys(onlineUsers).length);
    setState(Object.keys(onlineUsers).length);
  }, [list]);

  return (
    <>
      {loading && <span> Loading... </span>}{" "}
      {state > 1
        ? state + " users now connected "
        : state === 1
        ? "You are the only one here"
        : "No one around"}
    </>
  );
};

export const CountOnlineUsers = ({ eventId }) => {
  const [state, setState] = useState(0);
  const [list] = useList(ref(database, `isOnline/${eventId}`));

  useEffect(() => {
    if (!list) return null;
    const onlineUsers = list.reduce((prev, curr) => {
      return { ...prev, [curr.key]: true };
    }, {});
    setState(Object.keys(onlineUsers).length);
    return null;
  }, [list]);

  return state;
};

export const UpdateButtons = ({
  values,
  path,
  styles = {},
  initialValue = "",
}) => {
  const { updateOne } = useFirebaseUpdate(database);
  const [selected, setSelected] = useState();

  const handleChange = (newValue) => {
    setSelected(newValue);
    updateOne(newValue, path);
    console.log("user update", path, selected);
  };

  useEffect(() => {
    if (!initialValue) return null;
    setSelected(initialValue);
  }, [initialValue]);

  return (
    <ToggleButtonGroup
      type="radio"
      name={styles.name}
      value={selected}
      onChange={handleChange}
      className={styles.class}
      vertical={styles.vertical ? true : false}>
      {values.map((bt, idx) => {
        return (
          <ToggleButton
            key={`btn-${bt.value}-${idx}`}
            id={`btn-${bt.value}-${idx}`}
            value={bt.value}
            variant={styles.variant}
            size={styles.size}>
            {bt.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export const UpdateToggle = ({
  label,
  name,
  path,
  styles = {},
  initialValue = false,
}) => {
  //const eventId = event.id;
  const [checked, setChecked] = useState();
  const { updateOne } = useFirebaseUpdate(database);
  // buttons action move to component

  const handleToggle = (e) => {
    setChecked(e.currentTarget.checked);
    updateOne(!checked, path);
  };

  useEffect(() => {
    if (!initialValue) return null;
    setChecked(initialValue);
  }, [initialValue]);

  return (
    <ToggleButton
      id={`btn-${name}`}
      className={styles.class}
      variant={styles.variant ?? "outline-secondary"}
      size={styles.size}
      type="checkbox"
      checked={checked}
      value="1"
      onChange={handleToggle}>
      {label}
    </ToggleButton>
  );
};
