import React, { useState, useEffect } from "react";
import { useFirebaseUpdate } from "../../hooks/FirebaseHooks";
import { SvgCounter, IncomeRangeSlider } from "../UserView/UserViewActive";
import UserViewFinished from "../UserView/UserViewFinished";
import SocialIncomeLogo from "../../logo-animated.svg";

import Button from "react-bootstrap/Button";

function StandaloneUserView({ event, liveData, userId }) {
  const [step, setStep] = useState(1);
  const [income, setIncome] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleSteps = (e) => {
    let _step = step < 4 ? step + 1 : 1;
    setStep(_step);
  };

  return (
    <>
      {step === 1 && <StepWelcome {...{ handleSteps, event }} />}
      {step === 2 && (
        <StepIncome {...{ handleSteps, event, userId, income, setIncome }} />
      )}
      {step === 3 && (
        <StepSending {...{ handleSteps, event, income, userId }} />
      )}
      {step === 4 && <StepFinal {...{ setStep, event, income, userId }} />}
    </>
  );
}

function StepWelcome({ handleSteps, event }) {
  const panel = event.userViewOnboard || {};
  return (
    <>
      <div className="p-5 my-5 text-center">
        <img width="100%" src={SocialIncomeLogo} alt="" />
        <h3 className="display-1 mt-5">{panel.title}</h3>
        <p className="lead">{panel.desc}</p>
        <p style={{ whiteSpace: "pre" }}>{panel.content}</p>
      </div>

      <div className="text-center">
        <Button variant="warning w-100" size="lg" onClick={handleSteps}>
          Start
        </Button>
      </div>
    </>
  );
}

function StepIncome({ handleSteps, event, userId, income, setIncome }) {
  const eventId = event.id;
  return (
    <>
      <div
        className="px-3 mx-auto mt-4 text-center"
        style={{ maxWidth: "450px" }}>
        <SvgCounter {...{ income, event }} />
        <div>
          <div className="mt-3">
            <small>Use slider to adjust your monthly income</small>
            <IncomeRangeSlider
              autoSaveOff
              {...{ income, setIncome, event, userId }}
            />
          </div>
          <div className="text-center mt-5 pt-5">
            {income > 500 && (
              <Button variant="warning w-100" size="lg" onClick={handleSteps}>
                Show what {event.currency} {Math.round(income / 100)} does
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
function StepSending({ handleSteps, event, income, userId }) {
  const { incrementIncome, useInitialValues } = useFirebaseUpdate();
  const initialIncome = useInitialValues(`incomes/${event.id}/${userId}`);

  useEffect(() => {
    if (!income || income === initialIncome) return null;
    const timeoutId = setTimeout(() => {
      const delta = income - initialIncome;
      incrementIncome(event.id, userId, income, delta).then(() => {
        console.log("income set", delta);
      });
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [initialIncome]);

  useEffect(() => {
    let sendingTimer = setTimeout(handleSteps, 2000);
    return () => {
      clearTimeout(sendingTimer);
    };
  }, []);
  return (
    <>
      <p className="fs-4 text-center my-5">
        step 3: Sending:
        <br />
        <br />
        <strong className="fs-1">
          {event.currency} {Math.round(income / 100)}
        </strong>
        <br />
        <small>wait 2 seconds animation</small>
      </p>
    </>
  );
}
function StepFinal({ setStep, event, income, userId }) {
  return (
    <>
      <UserViewFinished {...{ event, income, userId }} />
      <Button variant="link text-reset" onClick={() => setStep(2)}>
        Edit Income
      </Button>
    </>
  );
}

export default StandaloneUserView;
