/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebaseApp from "../../services/initialize-firebase";
import { ref, getDatabase } from "firebase/database";
import {
  getFirestore,
  documentId,
  where,
  collection,
  query,
  limit,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useObject } from "react-firebase-hooks/database";

//import { useFirebaseUpdate } from "../../hooks/FirebaseHooks";
import { Loading } from "../../App";

const firestoreDB = getFirestore(firebaseApp);
const realtimeDB = getDatabase(firebaseApp);

export function StreamEvent({ children, ...rest }) {
  const { eventId, eventName } = useParams();
  const [event, setEvent] = useState();
  const navigate = useNavigate();

  // load document from firestore
  let docWhere = where(documentId(), "==", eventId);
  if (!eventId && eventName) docWhere = where("name", "==", eventName);
  const [snapshot, loading] = useCollection(
    query(collection(firestoreDB, "events"), docWhere, limit(1))
  );

  useEffect(() => {
    if (!loading && snapshot) {
      if (snapshot.docs[0]) {
        setEvent({ id: snapshot.docs[0].id, ...snapshot.docs[0].data() });
      } else {
        navigate("/");
      }
    }
  }, [snapshot]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        event,
        ...rest,
      });
    }
    return child;
  });
  if (!event) return <Loading label="Loading Event..." />;
  return <> {childrenWithProps} </>;
}

// *****
// StreamLiveData Component
// *****
export function StreamLiveData({ event, pathType, children }) {
  const [liveData, setliveData] = useState({});

  // streaming path
  // WTF is this ???
  let path;
  if (pathType === "userview") path = `events/${event.id}`;
  else path = `events/${event.id}`;

  const [snapshot, loading] = useObject(ref(realtimeDB, path));

  useEffect(() => {
    if (!loading) {
      setliveData(snapshot.val() || {});
    }
    console.log("Live Data is on", liveData);
  }, [snapshot]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        event,
        liveData,
      });
    }
    return child;
  });
  if (loading) return <Loading label="Loading Live Data..." />;
  return <> {childrenWithProps} </>;
}
