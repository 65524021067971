import { useOutletContext } from "react-router-dom";

function PresenterViewFinished() {
  const [event, liveData] = useOutletContext();

  return (
    <>
      <h2>Finito! {event.title}</h2>
      <span>total win: {liveData.income}</span>
    </>
  );
}

export default PresenterViewFinished;
