import React from "react";

import EditViewFormGenerator from "./EditViewFormGenerator";

function EditViewTabUserView({ formData, handleChange }) {
  // format Array => fieldname "", label "", options ?.{}
  const fields = [
    ["h3", "Onboard Panel"],
    ["userViewOnboard.title", "Title"],
    ["userViewOnboard.desc", "Lead"],
    ["userViewOnboard.content", "Text"],
    ["userViewOnboard.img", "Image / Logo"],
    ["hr"],
    ["h3", "Active Panel"],
    ["userViewActive.monthlyIncome", "Income title"],
    ["userViewActive.wouldHelp", "Would help text"],
    ["userViewActive.useSlider", "Use slider text"],
    ["hr"],
    ["h3", "Finished Panel"],
    ["userViewFinished.title", "Title"],
    ["userViewFinished.desc", "Lead"],
    ["userViewFinished.content", "Text"],
    ["userViewFinished.img", "Image / Logo"],
  ];

  return (
    <div className="form-block">
      {" "}
      {fields.map((field, idx) =>
        field[0] === "hr" ? (
          <hr key={`user-view-${idx}`} />
        ) : field[0] === "h3" ? (
          <div key={`user-view-${idx}`} className="fw-bold mb-3">
            {" "}
            {field[1]}{" "}
          </div>
        ) : (
          <EditViewFormGenerator
            key={`user-view-${idx}`}
            {...{ formData, handleChange, field }}
          />
        )
      )}{" "}
    </div>
  );
}
export default EditViewTabUserView;
