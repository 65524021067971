export const rootUrl = "https://impact.socialincome.org/";

export const initialDataEvent = {
  title: "New Event",
  name: "",
  date: "",
  where: "",
  description: "",
  lang: "en",
  currency: "CHF",
  host: "Sandino",
  hostemail: "sandino@socialincome.org",
  status: "draft",
  deleted: 0,
  min: 400,
  max: 14000,
  slidesid: "1gZ3DnBr64tLc3zAFZ0897-D8whZ0wXGJZGvcLptcOsk",
  userViewOnboard: {
    title: "Welcome on board",
    desc: "This is going to be a great event",
    content:
      "A short description of the content of the event. Adressing the audience and telling them something is going to happen here later",
    img: "",
  },
  userViewFinished: {
    title: "The event is finished",
    desc: "Thank you for your participation",
    content: "Let's stay in touch...",
    img: "",
  },
  userViewActive: {
    monthlyIncome: "Your monthly income",
    wouldHelp: "would help support",
    useSlider: "use the slider and the arrows to adjust your income",
  },
};

export const statusColors = {
  draft: "var(--cucolor1l)",
  onboard: "var(--blue)",
  active: "var(--blue)",
  finished: "var(--cucolor4l)",
  admin: "var(--bs-gray-300)",
  initial: "white",
  dark: "var(--dark)",
  light: "var(--light)",
};
export const impactPhrases = {
  1: "%%amount%% contribution lays the groundwork for a big cause. It helps pay Social Income for one person in need.",

  10: "%%amount%% contribution pays a third of Social Income for one person in need.",

  11: "%%amount%% contribution is a strong contribution to a big cause. That’s a quarter of an average monthly salary in Sierra Leone!",

  13: "%%amount%% contribution is a strong contribution to a big cause. That’s a third of an average monthly salary in Sierra Leone!",

  15: "%%amount%% contribution already covers half of Social Income for one person in need.",

  16: "%%amount%% contribution pays for more than half of someone’s Social Income.",

  18: "%%amount%% contribution covers most of Social Income for one person in need. That’s over half of an average monthly salary in Sierra Leone!",

  30: "Great! Your monthly contribution of %%amount%% contribution will allow one person in need to receive full Social Income.",

  31: "%%amount%% contribution covers Social Income for more than one person in need. You’ll impact one person directly, and even more indirectly.",

  60: "Your single contribution has double the impact! %%amount%% contribution covers Social Income for two people in need.",

  61: "%%amount%% contribution covers Social Income for more than two people in need.",

  500: "Your monthly contribution amount is higher than usual. Please get in touch with us for a tailor-made solution.",
};
