import React from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  Link,
} from "react-router-dom";
import { useFirestore } from "../../hooks/FirestoreHooks";

import Button from "react-bootstrap/Button";

function EventDelete() {
  const { eventId } = useParams();
  const [event] = useOutletContext();
  const navigate = useNavigate();
  const { deleteEvent } = useFirestore();

  function handleClick() {
    deleteEvent(eventId, "events").then(() => {
      console.log("deleted", eventId);
      navigate("/admin/events/list");
    });
  }

  return (
    <>
      <div className="p-5 mt-5 bg-light" style={{ borderRadius: "20px" }}>
        <p className="text-center">
          <strong> {event.title} </strong> <br />
          Are you sur you want to delete this item ?
        </p>
        <div className="d-flex justify-content-center gap-3">
          <Button variant="danger" size="lg" onClick={handleClick}>
            Delete
          </Button>{" "}
          <Button
            as={Link}
            variant="outline-secondary"
            size="lg"
            to="/admin/events/list">
            Oups, no..{" "}
          </Button>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
}
export default EventDelete;
