import { useOutletContext } from "react-router-dom";
import { rootUrl } from "../../data/initialValues";

import QRCode from "react-qr-code";

function PresenterViewQrCode() {
  const [event] = useOutletContext();
  return (
    <div className="text-center">
      <h2>Scan to participate</h2>
      <div className="mt-5 p-3">
        <QRCode
          value={rootUrl + event.name}
          size={350}
          bgColor="transparent"
          fgColor="#ffffff"
          renderas="svg"
        />
      </div>
      <div className="mt-5 p-3">
        <span className="fs-1 fw-bold">impact.socialincome.org/{event.name}</span> 
      </div>
    </div>

  );
}

export default PresenterViewQrCode;
