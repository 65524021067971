import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

// ICONS
import { MdMobileFriendly } from "react-icons/md";
import { HiOutlinePresentationChartBar, HiOutlineTrash } from "react-icons/hi";
import { TiEdit } from "react-icons/ti";

function ActionButtons({ eventId }) {
  return (
    <div className="d-inline-flex gap-2">
      {[
        {
          variant: "primary",
          to: `/e/${eventId}`,
          icon: <MdMobileFriendly />,
        },
        {
          variant: "primary",
          to: `/admin/events/${eventId}/presenter`,
          icon: <HiOutlinePresentationChartBar />,
        },
        {
          variant: "secondary",
          to: `/admin/events/${eventId}/edit`,
          icon: <TiEdit />,
        },
        {
          variant: "danger",
          to: `/admin/events/${eventId}/delete`,
          icon: <HiOutlineTrash />,
        },
      ].map((el, idx) => {
        return (
          <Button
            key={idx}
            variant={`outline-${el.variant}`}
            as={Link}
            size="sm"
            to={el.to}>
            {el.icon}
          </Button>
        );
      })}
    </div>
  );
}

export default ActionButtons;
