import React, { useState, useEffect, useCallback } from "react";
import {
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
  useOutletContext,
} from "react-router-dom";

import Login from "./components/Login/Login";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Badge from "react-bootstrap/Badge";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import SocialIncomeLogo from "./logo-animated.svg";
import SocialIncomeLogoSmall from "./logo-small.svg";

import * as FirestoreService from "./services/firestore";
import { useAuth } from "./hooks/useAuth";

import Test from "./_tests/Test";
import UserView from "./components/UserView/UserView";
import Dashboard from "./components/Dashboard/Dashboard";
import PresenterView from "./components/PresenterView/PresenterView";

// INITIAL DATA
// const rootUrl = "http://192.168.1.47:3000/";
import { initialDataEvent, rootUrl, statusColors } from "./data/initialValues";
import { StreamEvent, StreamLiveData } from "./components/_utils/Streamers";
import AdminEventMenu from "./components/_utils/AdminEventMenu";
import AdminEventTopMenu from "./components/_utils/AdminEventTopMenu";
import EditView from "./components/EditView/EditView";
import EventDelete from "./components/EditView/EventDelete";
import ActionButtons from "./components/_utils/ActionButtons";

// Event listener hook
/*
// event listener hook
const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = React.useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

  
    const handler = ({ key }) => {
      if (["27", "Escape"].includes(String(key))) {
        console.log("Escape key pressed!");
      }
      if (key === "a") {
        console.log(`${key} key pressed!`);
      }
    };
    useEventListener("keydown", ({ key }) => console.log(key));
    

*/

// APP
function App() {
  const [splashscreen, setSplashscreen] = useState(true);
  const [bgColor, setBgColor] = useState({});
  const [pageTitle, setPageTitle] = useState("");
  const bgColorProps = { bgColor, setBgColor, pageTitle, setPageTitle };

  const auth = useAuth();

  // AUTH LOGIC
  useEffect(() => {
    const user = auth.user;
    console.log("Auth logic reached");
    if (user == null) {
      console.log("loading...");
    } else {
      console.log("Auth Service returned something let's see...");
      if (user === false) {
        console.log("Oo this user is not logged in: make it ANON then");
        auth
          .anonsignin()
          .then((r) => console.log("anon signin response OK"))
          .catch((e) => console.log("errors", e.code));
      } else {
        setSplashscreen(false);
        if (user.isAnonymous) {
          console.log("Anonymous User");
        }
        if (!user.isAnonymous && user.email) {
          console.log("Admin User", user.email);
        }
      }
    }
  }, [auth]);

  const fullscreen = useFullScreenHandle();

  if (splashscreen) return <div className="splash" />;

  return (
    <FullScreen handle={fullscreen}>
      <div
        className="colorwrapper pb-5"
        style={{
          background: bgColor.color ?? statusColors.initial,
          color: bgColor.text ?? "var(--bs-dark)",
          transitionProperty: "background, color",
          transitionDuration: bgColor.time ?? "0s",
        }}>
        <Navbar bg="transparent">
          <Container fluid="lg">
            <div className="d-inline-flex align-items-center gap-2">
              <Navbar.Brand as={Link} to={auth.user.email ? "/admin" : "/"}>
                <img
                  alt=""
                  src={SocialIncomeLogo}
                  width="auto"
                  height="20"
                  className="d-none d-xl-inline-block"
                  style={{ marginTop: "-8px" }}
                />
                <img
                  alt=""
                  src={SocialIncomeLogoSmall}
                  width="auto"
                  height="31"
                  className="d-inline-block d-xl-none"
                />{" "}
              </Navbar.Brand>
              <span className="text-reset">{pageTitle}</span>
            </div>
          </Container>
        </Navbar>
        {/** Main content  */}
        <Container fluid="lg">
          <Routes>
            <Route
              path="/:eventName"
              element={<UserView {...bgColorProps} end />}
            />
            <Route
              path="/e/:eventId"
              element={<UserView {...bgColorProps} end />}
            />
            <Route path="/login" element={<Login {...bgColorProps} />} />
            <Route path="/admin" element={<Admin />}>
              <Route index element={<Dashboard {...bgColorProps} />} />
              <Route
                path="events/list"
                element={<EventsList {...bgColorProps} />}
              />
              <Route
                path="events/:eventId"
                element={<EventAdmin {...{ ...bgColorProps, fullscreen }} />}>
                <Route path="edit" element={<EditView {...bgColorProps} />} />
                <Route
                  path="presenter"
                  element={<PresenterView {...bgColorProps} />}
                />
                <Route path="remote" element={<Remote {...bgColorProps} />} />
                <Route path="delete" element={<EventDelete />} />
              </Route>
              <Route
                path="users"
                element={<UsersList {...bgColorProps} />}></Route>
            </Route>
            <Route path="/test" element={<Test {...bgColorProps} />} />
            <Route index element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Container>
      </div>
      <div className="d-none pt-2 pb-5 bg-dark text-light">
        <Container fluid="lg">
          <Row>
            <Col>
              <div className="small text-muted">
                {auth.user?.uid} | {auth.user?.email} |{" "}
              </div>
            </Col>
            <Col>
              {auth.user?.email ? (
                <Button
                  variant="link"
                  size="sm"
                  className="text-reset"
                  onClick={(e) => {
                    auth.signout();
                  }}>
                  Sign out
                </Button>
              ) : (
                <Link
                  className="text-reset text-muted small text-decoration-none"
                  to="/admin">
                  Admin
                </Link>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </FullScreen>
  );
}

function Admin() {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user || auth.user.isAnonymous || !auth.user.email) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const userId = auth.user.uid;
  const userEmail = auth.user.email;
  return <Outlet context={[userId, userEmail]} />;
}

function EventAdmin({ fullscreen, setPageTitle }) {
  const [userId, userEmail] = useOutletContext();

  // if (!event) return <Loading />;
  return (
    <StreamEvent>
      <StreamLiveData pathType="userview">
        <EventAdminOutlet
          {...{ userId, userEmail, fullscreen, setPageTitle }}
        />
      </StreamLiveData>
    </StreamEvent>
  );
}

function EventAdminOutlet({
  event,
  liveData,
  userId,
  userEmail,
  fullscreen,
  setPageTitle,
}) {
  const props = { event, liveData, userId, userEmail };

  useEffect(() => {
    setPageTitle(<AdminEventTopMenu {...props} />);
    return () => setPageTitle("");
  }, [setPageTitle]);

  return (
    <>
      <Outlet context={[event, liveData, userId, userEmail]} />
      <AdminEventMenu {...{ event, liveData, fullscreen }} />
    </>
  );
}

function Home() {
  const navigate = useNavigate();
  const [eventName, setEventName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!eventName) return null;

    FirestoreService.getEventId(eventName)
      .then((doc) => {
        if (doc.docs.length > 0) {
          navigate("/e/" + doc.docs[0].id);
        } else {
          setErrorMsg("no event with this name");
        }
      })
      .catch((e) => console.log("error getting event id", e.message));
  };

  const handleChange = (e) => {
    setEventName(e.target.value.trim().toLowerCase());
    setErrorMsg("");
  };
  return (
    <>
      <div className="mt-5 px-4 py-5">
        <h1 className="display-4" style={{ fontWeight: 800 }}>
          How many people can you lift out of poverty with 1% of your income?
        </h1>
        <p className="lead">
          Enter the event and alltogether enter your monthly income to calculate
          your impact.
        </p>
        <hr className="my-5" />

        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="urlField">
              <Col sm={6}>
                <Form.Control
                  type="text"
                  name="eventcode"
                  placeholder="Event Name or ID"
                  size="lg"
                  onChange={handleChange}
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                />
                <Form.Text id="passwordHelpBlock" muted>
                  {errorMsg}
                </Form.Text>
              </Col>
              <Col>
                <Button size="lg" type="submit">
                  Join now
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
}

// ****
// LIST EVENTS DASHBOARD
function EventsList({ bgColor, setBgColor }) {
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [error, setError] = useState();
  const [userId, userEmail] = useOutletContext();

  useEffect(() => {
    setBgColor({
      color: statusColors.admin,
    });
  }, [setBgColor]);

  useEffect(() => {
    FirestoreService.getEventList()
      .then((list) => {
        let eventData = [];
        list.forEach((doc) => {
          eventData = [...eventData, { id: doc.id, ...doc.data() }];
        });
        setEventList(eventData);
      })
      .catch((e) => setError("promise not resolved", e.code));
  }, []);

  // CREATE EVENT --> rewrite  ?
  function createEvent() {
    FirestoreService.add("events", initialDataEvent, userId)
      //FirestoreService.createEvent(initialDataEvent, userId)
      .then((doc) => {
        console.log("fucking create", doc);
        navigate(`/admin/events/${doc.id}/edit`);
      })
      .catch((e) => console.log("error adding list", e.message));
  }
  // unused component eventlist > td
  /*
  const eventListItems = eventList.map((event, i) => (
    <tr key={i} className="align-middle">
      <td>{i}</td>
      <td>{event.title}</td>
      <td>
        <small>{event.url}</small>
      </td>
      <td className="d-flex justify-content-end gap-2">
        {[
          { variant: "warning", to: "remote/", icon: "podcast" },
          { variant: "warning", to: "presenter/", icon: "eye" },
          { variant: "secondary", to: "event/", icon: "edit" },
        ].map((el, idx) => {
          return (
            <Button
              key={idx}
              variant={`outline-${el.variant}`}
              size="sm"
              as={Link}
              to={el.to + event.id}>
              <i className={`fa fa-${el.icon}`}></i>
            </Button>
          );
        })}
      </td>
    </tr>
  ));
  */

  return (
    <>
      <div className="d-flex flex-wrap align-items-center mt-2 gap-2">
        <h2 className="text-nobreak">Events</h2>
        <div className="flex-grow-1"></div>
      </div>
      <Row className="sticky-top py-3">
        <Col xs={8} lg={6} className="d-flex gap-2">
          <Form.Control
            type="text"
            size="lg"
            onChange={(e) => setFilterList(e.target.value.toLowerCase())}
            value={filterList}
            className="shadow"
            placeholder="Search event or status"
          />
          <Button
            variant="outline-secondary"
            className="shadow"
            size="lg"
            onClick={() => setFilterList("")}>
            <i className="fa fa-close"></i>
          </Button>
        </Col>
        <Col className=" d-flex justify-content-end">
          <Button onClick={createEvent} className="shadow">
            <i className="fa fa-plus"></i> Event
          </Button>
        </Col>
      </Row>
      {/*CARDS DISPLAY*/}
      <Row xs={1} md={3} className="g-3 mt-2 d-flex">
        {eventList
          .filter(
            (el) =>
              el.title.toLowerCase().includes(filterList) ||
              el.status.includes(filterList)
          )
          .map((event, idx) => (
            <Col className="d-flex align-content-stretch" key={idx}>
              <Card
                bg="light"
                text="dark"
                className="g-3 border-0 shadow-sm w-100">
                <Card.Body>
                  <Card.Title className="fs-5 fw-bolder">
                    {event.title}{" "}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {event.name}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted small">
                    {event.date ?? "19.12.2022"}
                  </Card.Subtitle>
                </Card.Body>
                <Card.Footer className="text-muted bg-light d-flex justify-content-end  align-items-center gap-2">
                  <div className="flex-grow-1">
                    <Badge bg="secondary">{event.status}</Badge>
                  </div>
                  <ActionButtons eventId={event.id} />
                </Card.Footer>
              </Card>
            </Col>
          ))}
      </Row>

      {""}
      {error}
    </>
  );
  /*}
      <div className="mt-3 p-2 bg-light rounded-2">
        <Table hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>URL</th>
              <th className="d-flex justify-content-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {eventList.length > 0 ? (
              eventListItems
            ) : (
              <tr>
                <td colSpan={4}>
                  <Loading label="Loading Event List" mh="200px" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
            {*/
}

// ****
// REMOTE
function Remote({ bgColor, setBgColor, setPageTitle, pageTitle }) {
  const [event, updateEvent] = useOutletContext();

  useEffect(() => {
    setBgColor({
      color: statusColors.dark,
      text: statusColors.light,
      time: "1s",
    });
    //return () => setPageTitle("");
  }, []);

  function onChange(value) {
    if (!(value === event.status)) {
      updateEvent({ status: value });
    }
  }

  return (
    <>
      <AdminHeader hr buttons {...{ event }} />
      <ToggleButtonGroup
        type="radio"
        name="statusRadio"
        value={event.status}
        onChange={onChange}
        className="d-flex"
        vertical>
        {["Draft", "Onboard", "Active", "Finished"].map((bt, idx) => {
          return (
            <ToggleButton
              key={"status-btn-" + idx}
              id={"status-btn-" + idx}
              value={bt.toLowerCase()}
              variant="outline-warning shadow-none"
              size="lg">
              {bt}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
}

function UsersList() {
  return <span>List of allowed user and access</span>;
}

/* -------------
******* COMPONENTS ********** 
--------------------- */

export function Loading({ label, mh }) {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: mh ?? "60vh" }}>
      <div className="d-inline-block text-center small text-muted">
        <i className="fa fa-cog fa-spin fa-3x"></i>
        <br />
        {label ?? "Loading"}
      </div>
    </div>
  );
}

export function AdminHeader({
  event,
  fullscreen,
  buttons,
  title,
  status,
  hr,
  msg,
}) {
  if (true) return <div className="mt-5"></div>;
  return (
    <>
      <div className="admin-header d-flex flex-wrap align-items-center mt-2 gap-2">
        <h2 className="text-nobreak">{title ?? event.title}</h2>
        {status && <Badge bg="secondary">{event.status}</Badge>}
        <div className="flex-grow-1"></div>
        {msg && <div className="small mx-3 text-muted">{msg}</div>}
        {/*fullscreen && <FullScreenToggle {...{ fullscreen }} />*/}
        {buttons && <ActionButtons eventId={event.id} />}
      </div>
      {hr && <hr />}
    </>
  );
}

export default App;
