import { useOutletContext } from "react-router-dom";
import { rootUrl } from "../../data/initialValues";
import SocialIncomeLogo from "../../logo-animated.svg";
import QRCode from "react-qr-code";

function PresenterViewStart() {
  const [event] = useOutletContext();
  const panel = event.presenterViewStart || {};
  return (
    <>
      <div className="responsive169">
        <svg viewBox="0 0 960 540" xmlns="http://www.w3.org/2000/svg">
          <use
            href={SocialIncomeLogo + "#logo"}
            x={(960 - 1492 * 0.4) / 2 + (1492 * 0.37) / 2}
            y="50%"
            transform="scale(.4)"
          />
          <text fill="var(--cucolor4ll)">
            <tspan
              textAnchor="middle"
              x="50%"
              y="43%"
              fontSize={36}
              fontWeight="bold">
              {panel.title}
            </tspan>
            <tspan textAnchor="middle" x="50%" y="63%" fontSize={28}>
              {panel.desc}
            </tspan>
            <tspan
              textAnchor="middle"
              x="50%"
              y="70%"
              fontSize={22}
              style={{ whiteSpace: "pre" }}>
              {panel.content}
            </tspan>
            <tspan textAnchor="middle" x="50%" y="77%" fontSize={22}>
              presented by {event.host}
            </tspan>
          </text>
        </svg>
      </div>

      <div
        className="fixed-bottom p-4 d-flex justify-content-center gap-5 w-100 rounded h-25"
        style={{ background: "var(--cucolor4d)" }}>
        <div className="text-center align-self-center w-50 fs-4 fw-bold">
          <p>scan to participate</p>
          <p>or</p>
          <p>
            <a
              className="text-reset"
              href={`https://impact.socialincome.org/${event.name}`}>
              impact.socialincome.org/{event.name}
            </a>
          </p>
        </div>
        <div className="w-25">
          <QRCode
            value={rootUrl + event.name}
            size={180}
            bgColor="transparent"
            fgColor={event.status === "active" ? "white" : "var(--dark)"}
            renderas="svg"
          />
        </div>
      </div>
    </>
  );
}

export default PresenterViewStart;
